$(document).ready(function() {
  // モックデータ
  const mockUserData = {
    userId: '0000000',
    userName: 'ユーザー名テキスト',
    password: 'password123'
  };

  // 初期ロード時のユーザー情報表示
  $('#userId').text(mockUserData.userId);
  $('#userName').text(mockUserData.userName);
  $('#passwordValue').text('・・・・・・・・');

  // パスワードの表示/非表示を切り替える処理
  $('#toggleMask').click(function() {
    if ($('#passwordValue').text().startsWith('・')) {
      $('#passwordValue').text(mockUserData.password);
      $('#passwordValue').addClass('is-active');
      $('#toggleMask img').attr('src', '../assets/images/icon/icon-eye_off.svg');
    } else {
      $('#passwordValue').text('・・・・・・・・');
      $('#passwordValue').removeClass('is-active');
      $('#toggleMask img').attr('src', '../assets/images/icon/icon-eye.svg');
    }
  });
});
